import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { SiteRFPSection } from './SiteRFPSection'
import { MetricsContainer } from './MetricsContainer'
import {
  RFPDocumentSummary,
  SiteRFPDocumentSummary,
} from '@black-bear-energy/black-bear-energy-common'

export const CustomerRFPSection: FunctionComponent<CustomerRFPSectionProps> = ({
  rfpDocumentSummary,
}: CustomerRFPSectionProps) => {
  const eventKey = rfpDocumentSummary.customerRFPId.toString()
  const siteRFPs = rfpDocumentSummary.siteRFPs
  const totals = customerRFPTotals(siteRFPs)

  return (
    <StyledCard>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <StyledHeaderContainer>
          <div>{rfpDocumentSummary.customerRFPName}</div>
          <MetricsContainer
            required={{
              numerator: totals.uploadedCounts.required,
              denominator: totals.totalCounts.required,
            }}
            reviewed={{
              numerator: totals.uploadedCounts.reviewed,
              denominator: totals.uploadedCounts.total,
            }}
          />
        </StyledHeaderContainer>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <StyledCardBody>
          {siteRFPs &&
            siteRFPs.map((siteRFP) => (
              <SiteRFPSection
                key={siteRFP.siteRFPId}
                siteRFPDocumentSummary={siteRFP}
              />
            ))}
        </StyledCardBody>
      </Accordion.Collapse>
    </StyledCard>
  )
}

interface CustomerRFPSectionProps {
  rfpDocumentSummary: RFPDocumentSummary
}

const StyledCard = styled(Card)`
  border-color: #cccccc;
`

const StyledHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const StyledCardBody = styled(Card.Body)`
  padding: 0;
`

interface CustomerRFPTotals {
  uploadedCounts: {
    required: number
    reviewed: number
    total: number
  }
  totalCounts: {
    required: number
  }
}

function customerRFPTotals(
  siteRFPs: SiteRFPDocumentSummary[]
): CustomerRFPTotals {
  const totals = siteRFPs.reduce(
    (acc, siteRFP) => {
      acc.uploadedCounts.required += siteRFP.uploadedCounts.required
      acc.uploadedCounts.reviewed += siteRFP.uploadedCounts.reviewed
      acc.uploadedCounts.total += siteRFP.uploadedCounts.total
      acc.totalCounts.required += siteRFP.totalCounts.required
      return acc
    },
    {
      uploadedCounts: {
        required: 0,
        reviewed: 0,
        total: 0,
      },
      totalCounts: {
        required: 0,
      },
    }
  )
  return totals
}
