import { FunctionComponent } from 'react'
import { Accordion } from 'react-bootstrap'
import { RFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'
import { CustomerRFPSection } from './CustomerRFPSection'

export const CustomerRFPList: FunctionComponent<CustomerRFPListProps> = ({
  rfpDocumentSummaries,
}: CustomerRFPListProps) => {
  if (!rfpDocumentSummaries) {
    return null
  }

  return (
    <Accordion>
      {rfpDocumentSummaries.map((rfpDocumentSummary) => (
        <CustomerRFPSection
          key={rfpDocumentSummary.customerRFPId}
          rfpDocumentSummary={rfpDocumentSummary}
        />
      ))}
    </Accordion>
  )
}

interface CustomerRFPListProps {
  rfpDocumentSummaries: RFPDocumentSummary[]
}
